
// import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import axios from "axios";
// import { useHistory } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
// // import WeeklyExam from "./exams/WeeklyExam";


// const WeeklyTests = () => {
//   const [tests, setTests] = useState([]);
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [id, setId] = useState([]);
//   // const location = useLocation();
//   const history = useHistory();
//   const timeOffsetHours = 5.75;
//   const [testname,setTestname] = useState([]);
//   const [testdate,setTestdate] = useState([]);

//   useEffect(() => {
//     // Fetch data from the API
//     axios
//       .get("https://nec.geoneer.com.np/api/exam/subjective-active-weekly-tests/SO-PSC/")
//       .then((response) => {
//         setTests(response.data); // Store the fetched data in the state
//         setIsLoaded(true); // Mark the data as loaded
//       })
//       .catch((error) => {
//         setError(error.message); // Handle errors
//         setIsLoaded(true);
//       });
//   }, []);



//   const handleWeeklyExam= (id,testname) => {
//     setId(id);
//     setTestname(testname);
    

//     // Use history.push to navigate to the '/chapterwise-exam' route with props
//     history.push({
//       pathname: '/weekly-subjective-exam',
     
//       state: {
//         id: id,
//         // chapterCode: chapterCode,
//         testname:testname,
//         // Add other props as needed
//       },
//     });
//   };

//   const handleEveryWeeklyExam= (id,testname,testdate) => {
//     setId(id);
//     setTestname(testname);
//     setTestdate(testdate);

//     // Use history.push to navigate to the '/chapterwise-exam' route with props
//     history.push({
//       pathname: '/weekly-subjective-exam-result',
     
//       state: {
//         id: id,
//         // chapterCode: chapterCode,
//         testname:testname,
//         testdate:testdate,
//         // Add other props as needed
//       },
//     });
//   };

  

//   const handleAnswerSheet= (id) => {
//     setId(id);
//     // setTestname(testname);
//     // setTestdate(testdate);

//     // Use history.push to navigate to the '/chapterwise-exam' route with props
//     history.push({
//       pathname: '/weekly-subjective-checked-answer',
     
//       state: {
//         id: id,
//         // chapterCode: chapterCode,
//         // testname:testname,
//         // testdate:testdate,
//         // Add other props as needed
//       },
//     });
//   };




//   const isCurrentTimeWithinRange = (testDate, startTime, endTime) => {
//     const currentTime = new Date(); // Local current time
//     const serverTime = new Date(currentTime.getTime());
//     //  - timeOffsetHours * 60 * 60 * 1000); // Adjust for server time

//     // Combine testDate with startTime and endTime to get valid test start and end times
//     const testStart = new Date(`${testDate}T${startTime}`); // Full start time
//     let testEnd = new Date(`${testDate}T${endTime}`); // Full end time

//     // Handle case where the end time is the next day (i.e., after midnight)
//     if (testEnd <= testStart) {
//       testEnd.setDate(testEnd.getDate() + 1); // Move end time to the next day
//     }

//     return serverTime >= testStart && serverTime <= testEnd; // Check if current time is within range
//   };




//   return (
//     <div className="container mt-4">
//       <div className="row">
//         <div className="col-lg-12">
//           <h2 className="text-center">Weekly Tests</h2>
//         </div>
//       </div>

//       <div className="table-responsive">
//         <table className="table table-bordered table-striped mt-3">
//           <thead className="thead-dark">
//             <tr>
//               <th>#</th>
//               <th>Test Name</th>
//               <th>Exam Date</th>
//               <th>Start Time</th>
//               <th>End Time</th>
//               <th>Status</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {error && (
//               <tr>
//                 <td colSpan="5" className="text-danger text-center">
//                   Error: {error}
//                 </td>
//               </tr>
//             )}

//             {!isLoaded && !error && (
//               <tr>
//                 <td colSpan="5" className="text-center">
//                   Loading...
//                 </td>
//               </tr>
//             )}

//             {isLoaded && tests.length === 0 && !error && (
//               <tr>
//                 <td colSpan="5" className="text-center">
//                   No active tests available.
//                 </td>
//               </tr>
//             )}

//             {isLoaded &&
//               tests.map((test, index) => (
//                 <tr key={test.id}>
//                   <td>{index + 1}</td>
//                   <td>{test.test_name}</td>
//                   <td>{test.test_date}</td>
//                   <td>{test.test_start_time}</td>
//                   <td>{test.test_end_time}</td>
//                   <td>{test.active_status ? "Active" : "Inactive"}</td>
//                   <td>
//                     <div
//                       className="btn-group"
//                       role="group"
//                       aria-label="Basic example"
//                     >
//                     {isCurrentTimeWithinRange(
//                       test.test_date,
//                       test.test_start_time,
//                       test.test_end_time
//                     ) && (
//                       <Button
//                         variant="success"
//                         onClick={() => handleWeeklyExam(test.id, test.test_name)}
//                       >
                      
//                         {console.log(id)}
//                         {console.log(testname)}
//                         Take Exam
//                       </Button>
//                     )}


                   
                        

//                     <Button className="btn btn-success btn-sm"
//                         variant="success" 
//                         onClick={() => handleEveryWeeklyExam(test.id, test.test_name,test.test_date)}
//                       >
                      
//                         {console.log(id)}
//                         {console.log(testname)}
//                         {console.log(testdate)}
//                         {console.log(timeOffsetHours)}
//                         {console.log(handleWeeklyExam)}

//                         View Results
//                       </Button>

                      
//                       <Button className="btn btn-success btn-sm"
//                         variant="success" 
//                         onClick={() => handleAnswerSheet(test.id)}
//                       >
                      
                   

//                         View AnswerSheet
//                       </Button>
//                     </div>
//                   </td>
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>


//       <div>
//       {/* {id !== null && (
//         <WeeklyExam id={id} />
//       )} */}
//     </div>

//     </div>
//   );
// };

// export default WeeklyTests;




import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { Calendar, Clock, FileText, AlertCircle, CheckCircle, Loader2 } from 'lucide-react';



const WeeklyTests = () => {
  const [tests, setTests] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [id, setId] = useState([]);
  const history = useHistory();

  const [testname, setTestname] = useState([]);
  const [testdate, setTestdate] = useState([]);

    const [courseCode, setCourseCode] = useState(null);
  
  
    
      useEffect(() => {
        const storedUserDetails = localStorage.getItem("userDetails");
        if (storedUserDetails) {
         const parsedUserDetails = JSON.parse(storedUserDetails);
          
          setCourseCode(parsedUserDetails.course.courseCode);
        }
      }, []);

  useEffect(() => {
    axios
      .get(`https://nec.geoneer.com.np/api/exam/subjective-active-weekly-tests/${courseCode}/`)
      .then((response) => {
        setTests(response.data);
        setIsLoaded(true);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoaded(true);
      });
  }, [courseCode]);

  // useEffect(() => {
  //   if (courseCode) {
  //     axios
  //       .get(`https://nec.geoneer.com.np/api/exam/active-weekly-tests/${courseCode}/`)
  //       .then((response) => {
  //         setTests(response.data);
  //         setIsLoaded(true);
  //       })
  //       .catch((error) => {
  //         setError(error.message);
  //         setIsLoaded(true);
  //       });
  //   }
  // }, [courseCode]);

  const handleWeeklyExam = (id, testname) => {
    setId(id);
    setTestname(testname);
    history.push({
      pathname: '/weekly-subjective-exam',
      state: {
        id: id,
        testname: testname,
      },
    });
  };

  const handleEveryWeeklyExam = (id, testname, testdate) => {
    setId(id);
    setTestname(testname);
    setTestdate(testdate);
    history.push({
      pathname: '/weekly-subjective-exam-result',
      state: {
        id: id,
        testname: testname,
        testdate: testdate,
      },
    });
  };

  const handleAnswerSheet = (id) => {
    setId(id);
    history.push({
      pathname: '/weekly-subjective-checked-answer',
      state: {
        id: id,
      },
    });
  };

  const isCurrentTimeWithinRange = (testDate, startTime, endTime) => {
    const currentTime = new Date();
    const serverTime = new Date(currentTime.getTime());
    const testStart = new Date(`${testDate}T${startTime}`);
    let testEnd = new Date(`${testDate}T${endTime}`);

    if (testEnd <= testStart) {
      testEnd.setDate(testEnd.getDate() + 1);
    }

    return serverTime >= testStart && serverTime <= testEnd;
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Weekly Tests</h2>

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded-md" role="alert">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            <p>Error: {error}</p>
          </div>
        </div>
      )}

      {!isLoaded && !error && (
        <div className="flex justify-center items-center h-64">
          <Loader2 className="h-8 w-8 text-blue-500 animate-spin" />
          <p className="ml-2 text-gray-600">Loading...</p>
        </div>
      )}

      {isLoaded && tests.length === 0 && !error && (
        <div className="text-center text-gray-600">
          <p>No active tests available.</p>
        </div>
      )}

      {isLoaded && tests.length > 0 && (
        <div className="overflow-x-auto bg-white shadow-md rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Test Name</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Exam Date</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tests.map((test, index) => (
                <tr key={test.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{test.test_name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center text-sm text-gray-500">
                      <Calendar className="h-5 w-5 mr-2 text-gray-400" />
                      {test.test_date}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center text-sm text-gray-500">
                      <Clock className="h-5 w-5 mr-2 text-gray-400" />
                      {test.test_start_time} - {test.test_end_time}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      test.active_status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {test.active_status ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex space-x-2">
                      {isCurrentTimeWithinRange(
                        test.test_date,
                        test.test_start_time,
                        test.test_end_time
                      ) && (
                        <button
                          onClick={() => handleWeeklyExam(test.id, test.test_name)}
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out flex items-center"
                        >
                          <CheckCircle className="h-4 w-4 mr-2" />
                          Take Exam
                        </button>
                      )}
                      
                                              {/* <button
                          onClick={() => handleWeeklyExam(test.id, test.test_name)}
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out flex items-center"
                        >
                          <CheckCircle className="h-4 w-4 mr-2" />
                          Take Exam
                        </button> */}
                      <button
                        onClick={() => handleEveryWeeklyExam(test.id, test.test_name, test.test_date)}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out flex items-center"
                      >
                        <FileText className="h-4 w-4 mr-2" />
                        View Results
                      </button>
                      <button
                        onClick={() => handleAnswerSheet(test.id)}
                        className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out flex items-center"
                      >
                        <FileText className="h-4 w-4 mr-2" />
                        View AnswerSheet
                      </button>
                    </div>
                  </td>
                </tr>
              ))}

{console.log(id)}
{console.log(testname)}
{console.log(testdate)}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default WeeklyTests;



