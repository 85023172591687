// "use client"

// import { useState, useEffect } from "react"
// import { Button, Card, Container, Row, Col } from "react-bootstrap"
// import { useHistory } from "react-router-dom"

// const ResourceChapter = () => {
//   const [chapters, setChapters] = useState([])
//   const [loading, setLoading] = useState(true)
//   const [error, setError] = useState(null)
//   const [courseCode, setCourseCode] = useState(null)
//   const history = useHistory()

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails")
//     if (storedUserDetails) {
//       const parsedUserDetails = JSON.parse(storedUserDetails)
//       setCourseCode(parsedUserDetails.course.courseCode)
//     }
//   }, [])

//   useEffect(() => {
//     const fetchChapters = async () => {
//       if (!courseCode) return
//       try {
//         const response = await fetch(`https://nec.geoneer.com.np/api/exam/chapters/${courseCode}/`)

//         if (!response.ok) {
//           throw new Error("Failed to fetch chapters")
//         }

//         const chaptersData = await response.json()
//         setChapters(chaptersData)
//       } catch (err) {
//         setError(err.message)
//       } finally {
//         setLoading(false)
//       }
//     }

//     fetchChapters()
//   }, [courseCode])

//   const ChapterCard = ({ chapter }) => (
//     <Card className="h-100 shadow-sm">
//       <Card.Img
//         variant="top"
//         src={`https://nec.geoneer.com.np${chapter.chapterImage}`}
//         alt={chapter.chapterName}
//         style={{ height: "180px", objectFit: "cover" }}
//       />
//       <Card.Body className="d-flex flex-column">
//         <Card.Title>{chapter.chapterName}</Card.Title>
//         <p>{chapter.chapterName}</p>
//         <div className="mt-auto">
//           <Button variant="success" size="sm" className="me-2" onClick={() => handlePractice(chapter)}>
//             Practice
//           </Button>

//         </div>
//       </Card.Body>
//     </Card>
//   )

//   const handlePractice = (chapter) => {
    
//     console.log("Practice for chapter:", chapter.chapterName)
//     // Add navigation logic here
//     history.push({
//       pathname: '/resource-materials',
   
//     });
//   }

//   const handleExplore = (chapter) => {
//     console.log("Explore chapter:", chapter.chapterName)
//     // Add navigation logic here
//   }

//   if (loading) {
//     return <Container className="text-center mt-4">Loading chapters...</Container>
//   }

//   if (error) {
//     return <Container className="text-center mt-4 text-danger">Error: {error}</Container>
//   }

//   return (
//     <Container fluid className="mt-4">
//       <h1 className="text-3xl font-bold mb-4">Course Chapters</h1>
//       {chapters.length > 0 ? (
//         <Row xs={1} md={2} lg={3} className="g-4">
//           {chapters.map((chapter) => (
//             <Col key={chapter.id}>
//               <ChapterCard chapter={chapter} />
//             </Col>
//           ))}
//         </Row>
//       ) : (
//         <p className="text-center">No chapters available for this course.</p>
//       )}
//     </Container>
//   )
// }

// export default ResourceChapter



// "use client"

// import { useState, useEffect } from "react"
// import { useHistory } from "react-router-dom"
// import { motion } from "framer-motion"
// import { Book, Loader2 } from 'lucide-react'

// const ResourceChapter = () => {
//   const [chapters, setChapters] = useState([])
//   const [loading, setLoading] = useState(true)
//   const [error, setError] = useState(null)
//   const [courseCode, setCourseCode] = useState(null)
//   const history = useHistory()

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails")
//     if (storedUserDetails) {
//       const parsedUserDetails = JSON.parse(storedUserDetails)
//       setCourseCode(parsedUserDetails.course.courseCode)
//     }
//   }, [])

//   useEffect(() => {
//     const fetchChapters = async () => {
//       if (!courseCode) return
//       try {
//         const response = await fetch(`https://nec.geoneer.com.np/api/exam/chapters/${courseCode}/`)

//         if (!response.ok) {
//           throw new Error("Failed to fetch chapters")
//         }

//         const chaptersData = await response.json()
//         setChapters(chaptersData)
//       } catch (err) {
//         setError(err.message)
//       } finally {
//         setLoading(false)
//       }
//     }

//     fetchChapters()
//   }, [courseCode])

//   const handlePractice = (chapterCode) => {

//     history.push({
//       pathname: '/resource-materials',
//       state: {
//         chapterCode,
//         courseCode
//       }
//     })
//   }

//   const ChapterCard = ({ chapter }) => (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       whileHover={{ y: -5 }}
//       className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
//     >
//       <div className="relative h-48 overflow-hidden">
  
//         {chapter.chapterImage ? (
//           <img
//             src={`https://nec.geoneer.com.np${chapter.chapterImage}`}
//             alt={chapter.chapterName}
//             className="w-full h-full object-cover"
//           />
//         ) : (
//           <div className="w-full h-full bg-gray-100 flex items-center justify-center">
//             <Book className="w-12 h-12 text-gray-400" />
//           </div>
//         )}
//       </div>
//       <div className="p-6">
//         <h3 className="text-xl font-semibold mb-3 line-clamp-2">{chapter.chapterName}</h3>
//         <div className="flex justify-start">
//           <button
//             onClick={() => handlePractice(chapter.chapterCode)}
//             className="bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-2 rounded-lg transition duration-300 flex items-center space-x-2"
//           >
//             Practice
//           </button>
//         </div>
//       </div>
//     </motion.div>
//   )

//   if (loading) {
//     return (
//       <div className="min-h-screen flex items-center justify-center">
//         <div className="flex items-center space-x-2">
//           <Loader2 className="w-6 h-6 animate-spin text-blue-500" />
//           <span className="text-lg">Loading chapters...</span>
//         </div>
//       </div>
//     )
//   }

//   if (error) {
//     return (
//       <div className="min-h-screen flex items-center justify-center">
//         <div className="bg-red-50 text-red-500 rounded-lg p-6 max-w-md text-center">
//           <h2 className="text-xl font-semibold mb-2">Error</h2>
//           <p>{error}</p>
//         </div>
//       </div>
//     )
//   }

//   return (
//     <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-7xl mx-auto">
//         <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">Course Chapters</h1>
//         {chapters.length > 0 ? (
//           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//             {chapters.map((chapter) => (
//               <ChapterCard key={chapter.id} chapter={chapter} />
//             ))}
//           </div>
//         ) : (
//           <div className="text-center text-gray-500 py-12">
//             <Book className="w-12 h-12 mx-auto mb-4 text-gray-400" />
//             <p className="text-lg">No chapters available for this course.</p>
//           </div>
//         )}
//       </div>
//     </div>
//   )
// }

// export default ResourceChapter

"use client"

import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { motion } from "framer-motion"
import { Book, Loader2, Calendar, Clock, User, Video } from "lucide-react"

const ResourceChapter = () => {
  const [chapters, setChapters] = useState([])
  const [classes, setClasses] = useState([])
  const [loading, setLoading] = useState(true)
  const [classesLoading, setClassesLoading] = useState(true)
  const [error, setError] = useState(null)
  const [classesError, setClassesError] = useState(null)
  const [courseCode, setCourseCode] = useState(null)
  const history = useHistory()

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails")
    if (storedUserDetails) {
      const parsedUserDetails = JSON.parse(storedUserDetails)
      setCourseCode(parsedUserDetails.course.courseCode)
    }
  }, [])

  useEffect(() => {
    const fetchChapters = async () => {
      if (!courseCode) return
      try {
        const response = await fetch(`https://nec.geoneer.com.np/api/exam/chapters/${courseCode}/`)

        if (!response.ok) {
          throw new Error("Failed to fetch chapters")
        }

        const chaptersData = await response.json()
        setChapters(chaptersData)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchChapters()
  }, [courseCode])

  useEffect(() => {
    const fetchClasses = async () => {
      if (!courseCode) return
      try {
        const response = await fetch(`https://nec.geoneer.com.np/api/exam/${courseCode}/class/`)

        if (!response.ok) {
          throw new Error("Failed to fetch classes")
        }

        const classesData = await response.json()
        setClasses(classesData)
      } catch (err) {
        setClassesError(err.message)
      } finally {
        setClassesLoading(false)
      }
    }

    fetchClasses()
  }, [courseCode])

  const handlePractice = (chapterCode) => {
    history.push({
      pathname: "/resource-materials",
      state: {
        chapterCode,
        courseCode,
      },
    })
  }

  const handleAttendClass = (jitsiLink) => {
    window.open(jitsiLink, "_blank", "noopener,noreferrer")
  }

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString)
      return date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })
    } catch (error) {
      return dateString
    }
  }

  const formatTime = (timeString) => {
    try {
      // Parse time in HH:MM:SS format
      const [hours, minutes] = timeString.split(":")
      const date = new Date()
      date.setHours(Number.parseInt(hours, 10))
      date.setMinutes(Number.parseInt(minutes, 10))

      return date.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", hour12: true })
    } catch (error) {
      return timeString
    }
  }

  const ClassCard = ({ classData }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-md overflow-hidden border border-emerald-100"
    >
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-2xl font-bold text-emerald-700">{classData.topic}</h3>
          <span className="bg-emerald-100 text-emerald-800 text-xs font-semibold px-3 py-1 rounded-full">
            Live Class
          </span>
        </div>

        <div className="space-y-3 mb-6">
          <div className="flex items-center text-gray-600">
            <User className="w-5 h-5 mr-2 text-emerald-600" />
            <span>{classData.teacher_name}</span>
          </div>
          <div className="flex items-center text-gray-600">
            <Calendar className="w-5 h-5 mr-2 text-emerald-600" />
            <span>{formatDate(classData.date)}</span>
          </div>
          <div className="flex items-center text-gray-600">
            <Clock className="w-5 h-5 mr-2 text-emerald-600" />
            <span>{formatTime(classData.time)}</span>
          </div>
        </div>

        <button
          onClick={() => handleAttendClass(classData.jitsi_link)}
          className="w-full bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-3 rounded-lg transition duration-300 flex items-center justify-center space-x-2"
        >
          <Video className="w-5 h-5" />
          <span>Attend Class</span>
        </button>
      </div>
    </motion.div>
  )

  const ChapterCard = ({ chapter }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: -5 }}
      className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
    >
      <div className="relative h-48 overflow-hidden">
        {chapter.chapterImage ? (
          <img
            src={`https://nec.geoneer.com.np${chapter.chapterImage}`}
            alt={chapter.chapterName}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-100 flex items-center justify-center">
            <Book className="w-12 h-12 text-gray-400" />
          </div>
        )}
      </div>
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-3 line-clamp-2">{chapter.chapterName}</h3>
        <div className="flex justify-start">
          <button
            onClick={() => handlePractice(chapter.chapterCode)}
            className="bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-2 rounded-lg transition duration-300 flex items-center space-x-2"
          >
            Practice
          </button>
        </div>
      </div>
    </motion.div>
  )

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex items-center space-x-2">
          <Loader2 className="w-6 h-6 animate-spin text-blue-500" />
          <span className="text-lg">Loading chapters...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-red-50 text-red-500 rounded-lg p-6 max-w-md text-center">
          <h2 className="text-xl font-semibold mb-2">Error</h2>
          <p>{error}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Available Classes Section */}
        <section className="mb-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">Available Classes</h1>

          {classesLoading ? (
            <div className="flex items-center justify-center py-12">
              <Loader2 className="w-6 h-6 animate-spin text-emerald-500 mr-2" />
              <span>Loading available classes...</span>
            </div>
          ) : classesError ? (
            <div className="bg-red-50 text-red-500 rounded-lg p-6 max-w-md mx-auto text-center">
              <p>{classesError}</p>
            </div>
          ) : classes.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {classes.map((classData) => (
                <ClassCard key={classData.id} classData={classData} />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500 py-12 bg-white rounded-xl shadow-sm">
              <Video className="w-12 h-12 mx-auto mb-4 text-gray-400" />
              <p className="text-lg">No classes available for this course right now.</p>
              <p className="text-sm text-gray-400 mt-2">Check back later for upcoming classes.</p>
            </div>
          )}
        </section>

        {/* Chapters Section */}
        <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">Course Chapters</h1>
        {chapters.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {chapters.map((chapter) => (
              <ChapterCard key={chapter.id} chapter={chapter} />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500 py-12">
            <Book className="w-12 h-12 mx-auto mb-4 text-gray-400" />
            <p className="text-lg">No chapters available for this course.</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResourceChapter

