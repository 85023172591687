// // "use client"

// // import { useState, useEffect } from "react"
// // import { motion } from "framer-motion"


// // const ResourceMaterials = () => {
// //   const [materials, setMaterials] = useState([])
// //   const [loading, setLoading] = useState(true)
// //   const [error, setError] = useState(null)
// //   const [selectedMaterial, setSelectedMaterial] = useState(null)



// //   useEffect(() => {
// //     const fetchMaterials = async () => {
// //       try {
// //         const response = await fetch("https://nec.geoneer.com.np/api/exam/PSC-S/EoS/materials/")
// //         if (!response.ok) {
// //           throw new Error("Failed to fetch materials")
// //         }
// //         const data = await response.json()
// //         setMaterials(data)
// //       } catch (err) {
// //         setError(err.message)
// //       } finally {
// //         setLoading(false)
// //       }
// //     }

// //     fetchMaterials()
// //   }, [])

// //   const handleShowDetails = (material) => {
// //     setSelectedMaterial(material)
// //   }

// //   const handleBackToList = () => {
// //     setSelectedMaterial(null)
// //   }

// //   const MaterialCard = ({ material }) => (
// //     <motion.div whileHover={{ y: -5 }} className="bg-white rounded-lg shadow-md overflow-hidden">
// //       <div className="p-4">
// //         <div className="flex items-center mb-3">
// //           <span className="mr-2 text-2xl">{material.resource_type === "pdf" ? "📄" : "🎥"}</span>
// //           <h3 className="text-lg font-semibold">{`${material.resource_type.toUpperCase()} Resource`}</h3>
// //         </div>
// //         <p className="flex items-center text-gray-600 mb-3">
// //           <span className="mr-2">👤</span> {material.teacher_name}
// //         </p>
// //         <button
// //           onClick={() => handleShowDetails(material)}
// //           className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
// //         >
// //           View Details
// //         </button>
// //       </div>
// //     </motion.div>
// //   )

// //   const MaterialDetails = ({ material }) => (
// //     <div className="bg-white rounded-lg shadow-xl overflow-hidden">
// //       <div className="p-6">
// //         <div className="flex justify-between items-center mb-4">
// //           <h2 className="text-3xl font-bold">{`${material.resource_type.toUpperCase()} Resource Details`}</h2>
// //           <button
// //             onClick={handleBackToList}
// //             className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-300"
// //           >
// //             Back to List
// //           </button>
// //         </div>
// //         <p className="mb-2 text-xl">
// //           <strong>Teacher:</strong> {material.teacher_name}
// //         </p>
// //         <div className="mb-6">
// //           <strong className="text-xl">Comments:</strong>
// //           <div dangerouslySetInnerHTML={{ __html: material.comments }} className="mt-2 text-lg" />
// //         </div>
// //         {material.resource_type === "pdf" && (
// //           <div className="mb-6">
// //             <h3 className="text-2xl font-semibold mb-2">PDF Preview</h3>
// //             <div className="h-[70vh] border border-gray-300 rounded-lg overflow-hidden">
// //               <iframe
// //                 src={`https://nec.geoneer.com.np${material.resource_file}#toolbar=0&navpanes=0&scrollbar=0`}
// //                 className="w-full h-full"
// //                 title="PDF Preview"
// //               ></iframe>
// //             </div>
// //           </div>
// //         )}
// //         {material.video_url && (
// //           <div className="mb-6">
// //             <h3 className="text-2xl font-semibold mb-2">Video Preview</h3>
// //             <div className="aspect-w-16 aspect-h-9 h-[70vh]">
// //               <iframe
// //                 src={`https://www.youtube.com/embed/${material.video_url.split("v=")[1]}`}
// //                 title="YouTube video player"
// //                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
// //                 allowFullScreen
// //                 className="w-full h-full rounded-lg"
// //               ></iframe>
// //             </div>
// //           </div>
// //         )}
// //         {material.video_url && (
// //           <div className="mt-4">
// //             <a
// //               href={material.video_url}
// //               target="_blank"
// //               rel="noopener noreferrer"
// //               className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
// //             >
// //               Watch on YouTube
// //             </a>
// //           </div>
// //         )}
// //       </div>
// //     </div>
// //   )

// //   if (loading) {
// //     return (
// //       <div className="container mx-auto text-center mt-20">
// //         <h2 className="text-2xl">Loading materials...</h2>
// //       </div>
// //     )
// //   }

// //   if (error) {
// //     return (
// //       <div className="container mx-auto text-center mt-20 text-red-500">
// //         <h2 className="text-2xl">Error: {error}</h2>
// //       </div>
// //     )
// //   }

// //   return (
// //     <div className="min-h-screen bg-gray-100 p-4 sm:p-6 md:p-8">
// //       {selectedMaterial ? (
// //         <MaterialDetails material={selectedMaterial} />
// //       ) : (
// //         <>
// //           <h1 className="text-4xl font-bold text-center mb-10">Course Materials</h1>
// //           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
// //             {materials.map((material) => (
// //               <MaterialCard key={material.id} material={material} />
// //             ))}
// //           </div>
// //         </>
// //       )}
// //     </div>
// //   )
// // }

// // export default ResourceMaterials


// "use client"

// import { useState, useEffect } from "react"
// import { motion, AnimatePresence } from "framer-motion"
// import { FileText, Video, User, ArrowLeft, ExternalLink } from "lucide-react"

// const ResourceMaterials = () => {
//   const [materials, setMaterials] = useState([])
//   const [loading, setLoading] = useState(true)
//   const [error, setError] = useState(null)
//   const [selectedMaterial, setSelectedMaterial] = useState(null)
//   const [pdfLoading, setPdfLoading] = useState(true)

//   useEffect(() => {
//     const fetchMaterials = async () => {
//       try {
//         const response = await fetch("https://nec.geoneer.com.np/api/exam/PSC-S/EoS/materials/")
//         if (!response.ok) {
//           throw new Error("Failed to fetch materials")
//         }
//         const data = await response.json()
//         setMaterials(data)
//       } catch (err) {
//         setError(err.message)
//       } finally {
//         setLoading(false)
//       }
//     }

//     fetchMaterials()
//   }, [])

//   const handleShowDetails = (material) => {
//     setPdfLoading(true)
//     setSelectedMaterial(material)
//   }

//   const handleBackToList = () => {
//     setSelectedMaterial(null)
//   }

//   const MaterialCard = ({ material }) => (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       exit={{ opacity: 0, y: -20 }}
//       whileHover={{ y: -5 }}
//       className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
//     >
//       <div className="p-6">
//         <div className="flex items-center mb-4">
//           {material.resource_type === "pdf" ? (
//             <FileText className="w-6 h-6 text-blue-500 mr-2" />
//           ) : (
//             <Video className="w-6 h-6 text-red-500 mr-2" />
//           )}
//           <h3 className="text-lg font-semibold">{material.resource_type.toUpperCase()}</h3>
//         </div>
//         <div className="space-y-3">
//           <p className="text-sm text-gray-600">{material.subchapter_name}</p>
//           <p className="flex items-center text-gray-700">
//             <User className="w-4 h-4 mr-2" />
//             {material.teacher_name}
//           </p>
//           <button
//             onClick={() => handleShowDetails(material)}
//             className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center"
//           >
//             View Details
//           </button>
//         </div>
//       </div>
//     </motion.div>
//   )

//   const MaterialDetails = ({ material }) => (
//     <motion.div
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       exit={{ opacity: 0 }}
//       className="bg-white rounded-xl shadow-xl overflow-hidden max-w-6xl mx-auto"
//     >
//       <div className="p-6 md:p-8">
//         <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
//           <div className="space-y-2 mb-4 md:mb-0">
//             <h2 className="text-2xl md:text-3xl font-bold">{material.subchapter_name}</h2>
//             <p className="text-gray-600">{material.chapter_code}</p>
//           </div>
//           <button
//             onClick={handleBackToList}
//             className="flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition duration-300"
//           >
//             <ArrowLeft className="w-4 h-4 mr-2" />
//             Back to List
//           </button>
//         </div>

//         <div className="space-y-6">
//           <div className="flex items-center">
//             <User className="w-5 h-5 mr-2 text-gray-600" />
//             <span className="text-lg">{material.teacher_name}</span>
//           </div>

//           {material.comments && (
//             <div className="bg-gray-50 rounded-lg p-4">
//               <h3 className="text-lg font-semibold mb-2"></h3>
//               <div dangerouslySetInnerHTML={{ __html: material.comments }} className="prose max-w-none" />
//             </div>
//           )}

//           {material.resource_type === "pdf" && (
//             <div className="space-y-4">
//               <h3 className="text-xl font-semibold">PDF Document</h3>
//               <div className="relative h-[70vh] border border-gray-200 rounded-lg overflow-hidden">
//                 {pdfLoading && (
//                   <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
//                     <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
//                   </div>
//                 )}
//                 <iframe
//                   src={`https://nec.geoneer.com.np${material.resource_file}#toolbar=0&navpanes=0&scrollbar=0`}
//                   className="w-full h-full"
//                   onLoad={() => setPdfLoading(false)}
//                   title="PDF Preview"
//                 />
//               </div>
//               <a
//                 href={`https://nec.geoneer.com.np${material.resource_file}`}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="inline-flex items-center text-blue-500 hover:text-blue-600"
//               >
//                 <ExternalLink className="w-4 h-4 mr-2" />
//                 Download PDF
//               </a>
//             </div>
//           )}

//           {material.video_url && (
//             <div className="space-y-4">
//               <h3 className="text-xl font-semibold">Video Content</h3>
//               <div className="relative aspect-video rounded-lg overflow-hidden bg-black">
//                 <iframe
//                   src={`https://www.youtube.com/embed/${material.video_url.split("v=")[1]}`}
//                   title="YouTube video player"
//                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                   allowFullScreen
//                   className="absolute top-0 left-0 w-full h-full"
//                 />
//               </div>
//               {/* <a
//                 href={material.video_url}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="inline-flex items-center text-red-500 hover:text-red-600"
//               >
//                 <ExternalLink className="w-4 h-4 mr-2" />
//                 Watch on YouTube
//               </a> */}
//             </div>
//           )}
//         </div>
//       </div>
//     </motion.div>
//   )

//   if (loading) {
//     return (
//       <div className="min-h-screen flex items-center justify-center">
//         <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
//       </div>
//     )
//   }

//   if (error) {
//     return (
//       <div className="min-h-screen flex items-center justify-center">
//         <div className="bg-red-50 text-red-500 rounded-lg p-6 max-w-md text-center">
//           <h2 className="text-xl font-semibold mb-2">Error</h2>
//           <p>{error}</p>
//         </div>
//       </div>
//     )
//   }

//   return (
//     <div className="min-h-screen bg-gray-50 p-4 md:p-8">
//       <AnimatePresence mode="wait">
//         {selectedMaterial ? (
//           <MaterialDetails key="details" material={selectedMaterial} />
//         ) : (
//           <motion.div
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             key="list"
//             className="space-y-8"
//           >
//             <h1 className="text-3xl md:text-4xl font-bold text-center">Course Materials</h1>
//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
//               {materials.map((material) => (
//                 <MaterialCard key={material.id} material={material} />
//               ))}
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   )
// }

// export default ResourceMaterials


// import { useState, useEffect ,useRef} from "react"
// import { motion, AnimatePresence } from "framer-motion"
// import { FileText, Video, User, ArrowLeft, ExternalLink } from "lucide-react"
// import { useLocation, useHistory } from "react-router-dom"
// import Plyr from "plyr-react";
// import "plyr-react/plyr.css"; // Import Plyr styles


// const ResourceMaterials = () => {
//   const [materials, setMaterials] = useState([])
//   const [loading, setLoading] = useState(true)
//   const [error, setError] = useState(null)
//   const [selectedMaterial, setSelectedMaterial] = useState(null)
//   const [pdfLoading, setPdfLoading] = useState(true)
//   const location = useLocation()
//   const history = useHistory()

//   // Get courseCode and chapterCode from location state
//   const courseCode = location.state?.courseCode
//   const chapterCode = location.state?.chapterCode




//   const playerRef = useRef(null)

//   useEffect(() => {
//     const hideYouTubeInfo = () => {
//       const iframe = document.querySelector(".plyr__video-embed iframe")
//       if (iframe) {
//         const overlay = document.createElement("div")
//         overlay.style.position = "absolute"
//         overlay.style.top = "0"
//         overlay.style.left = "0"
//         overlay.style.width = "100%"
//         overlay.style.height = "60px" // Adjust this value to cover the top bar
//         overlay.style.backgroundColor = "black"
//         overlay.style.zIndex = "10"
//         iframe.parentNode.insertBefore(overlay, iframe)
//       }
//     }

//     // Run once on mount and then every second for 5 seconds to ensure it applies after lazy loading
//     hideYouTubeInfo()
//     const interval = setInterval(hideYouTubeInfo, 1000)
//     setTimeout(() => clearInterval(interval), 5000)

//     return () => clearInterval(interval)
//   }, [])




//   useEffect(() => {
//     const fetchMaterials = async () => {
//       if (!courseCode || !chapterCode) {
//         setError("Missing course or chapter information")
//         setLoading(false)
//         return
//       }

//       try {
//         const response = await fetch(`https://nec.geoneer.com.np/api/exam/${courseCode}/${chapterCode}/materials/`)
//         if (!response.ok) {
//           throw new Error("Failed to fetch materials")
//         }
//         const data = await response.json()
//         setMaterials(data)
//       } catch (err) {
//         setError(err.message)
//       } finally {
//         setLoading(false)
//       }
//     }

//     fetchMaterials()
//   }, [courseCode, chapterCode])

//   const handleShowDetails = (material) => {
//     setPdfLoading(true)
//     setSelectedMaterial(material)
//   }

//   const handleBackToList = () => {
//     setSelectedMaterial(null)
//   }

//   const handleBackToChapters = () => {
//     history.goBack()
//   }

  


//   const extractYouTubeID = (url) => {
//     const regex = /(?:youtu\.be\/|youtube\.com\/(?:.*v=|.*\/v\/|.*\/embed\/|.*\/shorts\/))([\w-]+)/;
//     const match = url.match(regex);
//     return match ? match[1] : null;
//   };


//   const MaterialCard = ({ material }) => (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       exit={{ opacity: 0, y: -20 }}
//       whileHover={{ y: -5 }}
//       className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
//     >
//       <div className="p-6">
//         <div className="flex items-center mb-4 gap-x-4">
//           {/* {material.resource_type != "pdf" ? (
            
            
//           ) : ( */}
         

            
//           {/* )} */}
//           <FileText className="w-6 h-6 text-blue-500 mr-2" /> <h3 className="text-lg font-semibold">PDF </h3>
// <Video className="w-6 h-6 text-red-500 mr-2" /> <h3 className="text-lg font-semibold">VIDEO </h3>

//         </div>
//         <div className="space-y-3">
//           <p className="text-sm text-gray-600">{material.subchapter_name}</p>
//          <bold> <div dangerouslySetInnerHTML={{ __html: material.comments }} className="prose max-w-none" /> </bold>
//           <p className="flex items-center text-gray-700">
//             <User className="w-4 h-4 mr-2" />
//             {material.teacher_name}
//           </p>
//           <button
//             onClick={() => handleShowDetails(material)}
//             className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center"
//           >
//             View Details
//           </button>
//         </div>
//       </div>
//     </motion.div>
//   )

//   const MaterialDetails = ({ material }) => (
//     <motion.div
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       exit={{ opacity: 0 }}
//       className="bg-white rounded-xl shadow-xl overflow-hidden max-w-6xl mx-auto"
//     >
//       <div className="p-6 md:p-8">
//         <br></br>
//         <br></br>
//         <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
//           <div className="space-y-2 mb-4 md:mb-0">
//             <h2 className="text-2xl md:text-3xl font-bold">{material.subchapter_name}</h2>
//             <p className="text-gray-600">{material.chapter_code}</p>
//           </div>
//           <button
//             onClick={handleBackToList}
//             className="flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition duration-300"
//           >
//             <ArrowLeft className="w-4 h-4 mr-2" />
//             Back to List
//           </button>
//         </div>

//         <div className="space-y-6">
//           <div className="flex items-center">
//             <User className="w-5 h-5 mr-2 text-gray-600" />
//             <span className="text-lg">{material.teacher_name}</span>
//           </div>

//           {material.comments && (
//             <div className="bg-gray-50 rounded-lg p-4">
//               <h3 className="text-lg font-semibold mb-2">Addition Notes</h3>
//               <div dangerouslySetInnerHTML={{ __html: material.comments }} className="prose max-w-none" />
//             </div>
//           )}

//           {material.resource_type === "pdf" && (
//             <div className="space-y-4">
//               <h3 className="text-xl font-semibold">PDF Document</h3>
//               <div className="relative h-[70vh] border border-gray-200 rounded-lg overflow-hidden">
//                 {pdfLoading && (
//                   <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
//                     <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
//                   </div>
//                 )}
//                 <iframe
//                   src={`https://nec.geoneer.com.np${material.resource_file}#toolbar=0&navpanes=0&scrollbar=0`}
//                   className="w-full h-full"
//                   onLoad={() => setPdfLoading(false)}
//                   title="PDF Preview"
//                 />
//               </div>
//               <a
//                 href={`https://nec.geoneer.com.np${material.resource_file}`}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="inline-flex items-center text-blue-500 hover:text-blue-600"
//               >
//                 <ExternalLink className="w-4 h-4 mr-2" />
//                 Download PDF
//               </a>
//             </div>
//           )}

//           {material.video_url && (
//             // <div className="space-y-4">
//             //   <h3 className="text-xl font-semibold">Video Content</h3>
//             //   <div className="relative aspect-video rounded-lg overflow-hidden bg-black">
//             //     <iframe
//             //       src={`https://www.youtube.com/embed/${extractYouTubeID(material.video_url)}?modestbranding=1&rel=0&showinfo=0&controls=0`}
//             //       title="YouTube video player"
//             //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             //       allowFullScreen
//             //       className="absolute top-0 left-0 w-full h-full"
//             //     />
//             //   </div>
//             // </div>
//           //   <div className="space-y-4">
//           //   <h3 className="text-xl font-semibold">Video Content</h3>
//           //   <div className="relative aspect-video rounded-lg overflow-hidden bg-black">
//           //     <Plyr
//           //       source={{
//           //         type: "video",
//           //         sources: [
//           //           {
//           //             src: extractYouTubeID(material.video_url), // Extracted YouTube ID
//           //             provider: "youtube",
//           //           },
//           //         ],
//           //       }}
//           //       options={{
//           //         controls: ["play", "progress", "mute", "volume", "pip", "fullscreen"],
//           //         settings:["quality"],
//           //         youtube: {
//           //           modestbranding: 1,
//           //           rel: 0,
//           //           showinfo: 0,
//           //           iv_load_policy: 3,
//           //         },
//           //       }}
//           //     />
//           //   </div>
//           // </div>



//           <div className="space-y-4">
//           <h3 className="text-xl font-semibold">Video Content</h3>
//           <div
//             className="relative aspect-video rounded-lg overflow-hidden bg-black plyr__video-embed"
//             style={{
//               width: "100%",
//               height: "50%", // Covers only the top half
//               background: "black",
//             }}
//           >
//             <Plyr
//               ref={playerRef}
//               source={{
//                 type: "video",
//                 sources: [
//                   {
//                     src: extractYouTubeID(material.video_url),
//                     provider: "youtube",
//                   },
//                 ],
//               }}
//               options={{
//                 controls: ["play", "progress", "mute", "volume", "pip", "fullscreen"],
//                 settings: ["quality", "speed"],
//                 clickToPlay: true,
//                 disableContextMenu: true,
//                 hideControls: false,
//                 resetOnEnd: true,
//                 youtube: {
//                   noCookie: true,
//                   rel: 0,
//                   showinfo: 0,
//                   iv_load_policy: 3,
//                   modestbranding: 1,
//                   playsinline: 1,
//                   disablekb: 1,
//                   fs: 0,
//                   controls: 0,
//                   cc_load_policy: 0, // Disable closed captions
//                   origin: window.location.origin, // Set the origin to your domain
//                 },
//               }}
//             />
//           </div>
//         </div>


//           )}
//         </div>
//       </div>
//     </motion.div>
//   )

//   if (loading) {
//     return (
//       <div className="min-h-screen flex items-center justify-center">
//         <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
//       </div>
//     )
//   }

//   if (error) {
//     return (
//       <div className="min-h-screen flex items-center justify-center">
//         <div className="bg-red-50 text-red-500 rounded-lg p-6 max-w-md text-center">
//           <h2 className="text-xl font-semibold mb-2">Error</h2>
//           <p>{error}</p>
//           <button
//             onClick={handleBackToChapters}
//             className="mt-4 inline-flex items-center px-4 py-2 bg-red-100 hover:bg-red-200 rounded-lg transition duration-300"
//           >
//             <ArrowLeft className="w-4 h-4 mr-2" />
//             Back to Chapters
//           </button>
//           {console.log(chapterCode)}
//         </div>
//       </div>
//     )
//   }

//   return (
//     <div className="min-h-screen bg-gray-50 p-4 md:p-8">
//       <AnimatePresence mode="wait">
//         {selectedMaterial ? (
//           <MaterialDetails key="details" material={selectedMaterial} />
//         ) : (
//           <motion.div
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             key="list"
//             className="space-y-8"
//           >

//             <div className="flex items-center justify-between max-w-7xl mx-auto mb-8">
//                 <br>
//                 </br>
//                 <br>
//                 </br>
//                 <br></br>
//                 <br></br>
//               <h1 className="text-3xl md:text-4xl font-bold">Course Materials</h1>
//               <button
//                 onClick={handleBackToChapters}
//                 className="flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition duration-300"
//               >
//                 <ArrowLeft className="w-4 h-4 mr-2" />
//                 Back to Chapters
//               </button>
//             </div>
//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
//               {materials.map((material) => (
//                 <MaterialCard key={material.id} material={material} />
//               ))}
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   )
// }

// export default ResourceMaterialso




import { useState, useEffect, useRef } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { FileText, Video, User, ArrowLeft, ExternalLink, Maximize } from "lucide-react"
import { useLocation, useHistory } from "react-router-dom"
import Plyr from "plyr-react"
import "plyr-react/plyr.css"

const ResourceMaterials = () => {
  const [materials, setMaterials] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedMaterial, setSelectedMaterial] = useState(null)
  const [pdfLoading, setPdfLoading] = useState(true)
  const location = useLocation()
  const history = useHistory()

  const courseCode = location.state?.courseCode
  const chapterCode = location.state?.chapterCode

  const playerRef = useRef(null)

  useEffect(() => {
    const hideYouTubeInfo = () => {
      const iframe = document.querySelector(".plyr__video-embed iframe")
      if (iframe) {
        const overlay = document.createElement("div")
        overlay.style.position = "absolute"
        overlay.style.top = "0"
        overlay.style.left = "0"
        overlay.style.width = "100%"
        overlay.style.height = "60px"
        overlay.style.backgroundColor = "black"
        overlay.style.zIndex = "10"
        iframe.parentNode.insertBefore(overlay, iframe)
      }
    }

    hideYouTubeInfo()
    const interval = setInterval(hideYouTubeInfo, 1000)
    setTimeout(() => clearInterval(interval), 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const fetchMaterials = async () => {
      if (!courseCode || !chapterCode) {
        setError("Missing course or chapter information")
        setLoading(false)
        return
      }

      try {
        const response = await fetch(`https://nec.geoneer.com.np/api/exam/${courseCode}/${chapterCode}/materials/`)
        if (!response.ok) {
          throw new Error("Failed to fetch materials")
        }
        const data = await response.json()
        setMaterials(data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchMaterials()
  }, [courseCode, chapterCode])

  const handleShowDetails = (material) => {
    setPdfLoading(true)
    setSelectedMaterial(material)
  }

  const handleBackToList = () => {
    setSelectedMaterial(null)
  }

  const handleBackToChapters = () => {
    history.goBack()
  }

  const extractYouTubeID = (url) => {
    const regex = /(?:youtu\.be\/|youtube\.com\/(?:.*v=|.*\/v\/|.*\/embed\/|.*\/shorts\/))([\w-]+)/
    const match = url.match(regex)
    return match ? match[1] : null
  }

  const MaterialCard = ({ material }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      whileHover={{ y: -5 }}
      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
    >
      <div className="p-6">
        <div className="flex items-center mb-4 gap-x-4">
          {material.resource_file && (
            <>
              <FileText className="w-6 h-6 text-blue-500 mr-2" />
              <h3 className="text-lg font-semibold">PDF</h3>
            </>
          )}
          {material.video_url && (
            <>
              <Video className="w-6 h-6 text-red-500 mr-2" />
              <h3 className="text-lg font-semibold">VIDEO</h3>
            </>
          )}
        </div>
        <div className="space-y-3">
          <p className="text-sm text-gray-600">{material.subchapter_name}</p>
          <div dangerouslySetInnerHTML={{ __html: material.comments }} className="prose max-w-none" />
          <p className="flex items-center text-gray-700">
            <User className="w-4 h-4 mr-2" />
            {material.teacher_name}
          </p>
          <button
            onClick={() => handleShowDetails(material)}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center"
          >
            View Details
          </button>
        </div>
      </div>
    </motion.div>
  )

  const MaterialDetails = ({ material }) => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-white rounded-xl shadow-xl overflow-hidden max-w-6xl mx-auto"
    >
      <div className="p-6 md:p-8">
        <br />
        <br />
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <div className="space-y-2 mb-4 md:mb-0">
            <h2 className="text-2xl md:text-3xl font-bold">{material.subchapter_name}</h2>
            <p className="text-gray-600">{material.chapter_code}</p>
          </div>
          <button
            onClick={handleBackToList}
            className="flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition duration-300"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to List
          </button>
        </div>

        <div className="space-y-6">
          <div className="flex items-center">
            <User className="w-5 h-5 mr-2 text-gray-600" />
            <span className="text-lg">{material.teacher_name}</span>
          </div>

          {material.comments && (
            <div className="bg-gray-50 rounded-lg p-4">
              <h3 className="text-lg font-semibold mb-2">Additional Notes</h3>
              <div dangerouslySetInnerHTML={{ __html: material.comments }} className="prose max-w-none" />
            </div>
          )}

          {material.resource_file && (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">PDF Document</h3>
              <div className="relative h-[70vh] border border-gray-200 rounded-lg overflow-hidden">
                {pdfLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                  </div>
                )}
                <iframe
                  id="pdf-viewer"
                  src={`https://nec.geoneer.com.np${material.resource_file}#toolbar=1&navpanes=1&scrollbar=1&page=1&view=FitH`}
                  className="w-full h-full"
                  onLoad={() => setPdfLoading(false)}
                  title="PDF Preview"
                />
              </div>
              <div className="flex justify-between items-center">
                <a
                  href={`https://nec.geoneer.com.np${material.resource_file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-500 hover:text-blue-600"
                >
                  <ExternalLink className="w-4 h-4 mr-2" />
                  Download PDF
                </a>
                <button
                  onClick={() => {
                    const elem = document.getElementById("pdf-viewer")
                    if (elem.requestFullscreen) {
                      elem.requestFullscreen()
                    } else if (elem.webkitRequestFullscreen) {
                      /* Safari */
                      elem.webkitRequestFullscreen()
                    } else if (elem.msRequestFullscreen) {
                      /* IE11 */
                      elem.msRequestFullscreen()
                    }
                  }}
                  className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                >
                  <Maximize className="w-4 h-4 mr-2" />
                  Full Screen
                </button>
              </div>
            </div>
          )}

          {material.video_url && (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Video Content</h3>
              <div
                className="relative aspect-video rounded-lg overflow-hidden bg-black plyr__video-embed"
                style={{
                  width: "100%",
                  height: "50%",
                  background: "black",
                }}
              >
                <style jsx>{`
                  .plyr__video-embed iframe {
                    top: -50% !important;
                    height: 200% !important;
                  }
                  
                  /* Hide YouTube title and buttons when video is paused */
                  .plyr--paused.plyr__video-embed iframe {
                    pointer-events: none;
                  }
                  
                  /* Additional layer to prevent interaction with YouTube elements */
                  .plyr__video-wrapper::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 10;
                    pointer-events: none;
                  }
                `}</style>
                <Plyr
                  ref={playerRef}
                  source={{
                    type: "video",
                    sources: [
                      {
                        src: extractYouTubeID(material.video_url),
                        provider: "youtube",
                      },
                    ],
                  }}
                  options={{
                    controls: ["play", "progress", "current-time", "duration", "mute", "volume", "fullscreen"],
                    settings: ["quality", "speed"],
                    clickToPlay: true,
                    disableContextMenu: true,
                    hideControls: false,
                    resetOnEnd: true,
                    youtube: {
                      noCookie: true,
                      rel: 0,
                      showinfo: 0,
                      iv_load_policy: 3,
                      modestbranding: 1,
                      playsinline: 1,
                      disablekb: 1,
                      controls: 0,
                      cc_load_policy: 0,
                      origin: window.location.origin,
                      enablejsapi: 1,
                      widget_referrer: window.location.origin,
                      autoplay: 0,
                      hl: "en",
                      color: "white",
                      annotations: false,
                      playerVars: {
                        controls: 0,
                        showinfo: 0,
                        modestbranding: 1,
                        rel: 0,
                        enablejsapi: 1,
                        playsinline: 1,
                        iv_load_policy: 3,
                        cc_load_policy: 0,
                        hl: "en",
                        color: "white",
                        disablekb: 1,
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-red-50 text-red-500 rounded-lg p-6 max-w-md text-center">
          <h2 className="text-xl font-semibold mb-2">Error</h2>
          <p>{error}</p>
          <button
            onClick={handleBackToChapters}
            className="mt-4 inline-flex items-center px-4 py-2 bg-red-100 hover:bg-red-200 rounded-lg transition duration-300"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Chapters
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4 md:p-8">
      <AnimatePresence mode="wait">
        {selectedMaterial ? (
          <MaterialDetails key="details" material={selectedMaterial} />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="list"
            className="space-y-8"
          >
            <div className="flex items-center justify-between max-w-7xl mx-auto mb-8">
              <br />
              <br />
              <br />
              <br />
              <h1 className="text-3xl md:text-4xl font-bold">Course Materials</h1>
              <button
                onClick={handleBackToChapters}
                className="flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition duration-300"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Back to Chapters
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
              {materials.map((material) => (
                <MaterialCard key={material.id} material={material} />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ResourceMaterials

